import React from "react"
import Img from "gatsby-image"

// import  Logo from '../images/logo.svg';

import Layout from "../layouts"
import SEO from "../components/seo"

const ContactPage = ({data}) => {
    let page = data.wordpressPage
    let hero = page.acf.contact_hero_image.localFile.childImageSharp.fluid
    let contacts = page.acf.contact_list
    return (
        <Layout page="contact" >
            <SEO title="Visit Films - Contact Us"/>
            <section className="section hero hero--contact">
                <div className="container">
                <div className="hero__item">
                    {hero != null? <Img src={hero.src} fluid={hero} />: null}
                </div>
                </div>
            </section>
            <section className="section contacts-section">
            <h3 className="section__header"><span>Contact Us</span></h3>
                <div className="container">
                    <div className="contact__list">
                        {contacts.map((c, i) => 
                            <div className="contact" key={"contact-" + i}>
                                <div className="contact__title"><span>{c.contact_title}</span></div>
                                <div className="contact__details" dangerouslySetInnerHTML={{ __html: c.contact_address }} />
                            </div>
                        )}
                    </div>
                    
                </div>
            </section>
        </Layout>
    )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    wordpressPage(slug: {eq: "contact"}) {
        title
        acf {
            contact_hero_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            contact_list {
                contact_address
                contact_title
            }
        }
    }
  }
`
